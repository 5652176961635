@media screen and (min-device-width:768px){
    .TextFieldBox{
        display: flex;
    }
    .mapCont{
        margin-top: -27%;
        margin-left: -28%;
        width: 900px;
    
        
       
        
    }
    .rights{
        font-size: 12px;
        text-align: right;
        color: gray
    }
    .rights a{
        color: blue;
    }
    .MapBox{
        height: 500px !important;
    }
    .distanceBox{
        margin-top: -20%;
      
    }
    .entry{
        
        margin-top: 3%;
        background-color: white;
        margin-right: 0;
        height: 600px;
        border-radius: 10px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
    
    }
    .outlets{
        padding: 10px;
        border-radius: 10px;
    }
    .Content{
        margin-left: -5%;
    }
    .locationBtn{
        margin-left: -6%;
        color: white;
        font-weight: 500;
        border-radius: 3px;
        background-color: blue;
        padding:10px 20px 10px 20px;
        
    }
    .textfield1 select{
        border: 1px solid black;
    }
    .orderInput input{
        width: 70%;
        margin-top: 2%;
        height: 35px;
        border: 1px solid gray;
        border-radius: 5px;
        color:gray
        
    
    }
    .orderInput input::placeholder{
        color:gray;
    }
    .orderInput select::placeholder{
        color:gray;
    }
    .orderInput textarea::placeholder{
        color:gray;
    }
    .orderInput textarea{
        width: 70%;
        margin-top: 2%;
        height: 75px;
        border: 1px solid gray;
        border-radius: 5px;
        resize: none;
        
    
    }
    .orderInput select{
        width: 70%;
        margin-top: 2%;
        height: 35px;
        border: 1px solid gray;
        border-radius: 5px;
        resize: none;
        color: gray;
        
    
    }
    .orderInput input:focus{
        outline: none;
        border: 1px solid rgb(5, 129, 174);
    }
    
    .orderInput textarea:focus{
        outline: none;
        border: 1px solid rgb(5, 129, 174);
    }
    .orderInput select:focus{
        outline: none;
        border: 1px solid rgb(5, 129, 174);
    }
    .neworderTitle{
        color: rgb(63, 63, 63);
        margin-top: 2%;
        font-weight: 500;
    }
    
    .submitBtn{
        padding: 3px 8px 3px 8px;
        margin-top: 2%;
        color: white;
        border-radius: 3px;
        transition-duration: 0.4s;
        background-color: rgb(84, 174, 197);
    }
    .submitBtn:hover{
        background-color: rgb(4, 81, 101);
    
    }
}
@media screen and (max-width:468px){
    .TextFieldBox{
        display: flex;
    }
    .mapCont{
        margin-top: -25%;
        margin-left: -28%;
        width: 600px;
    
        
       
        
    }
    .MapBox{
        height: 500px !important;
    }
    .distanceBox{
        margin-top: -20%;
      
    }
    .entry{
        
        margin-top: 25%;
        background-color: white;
        margin-right: 0;
        margin-left: -30%;
        height: 600px;
        border-radius: 10px;
        box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -webkit-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
        -moz-box-shadow: -1px 3px 3px -1px rgba(0,0,0,0.44);
    
    }
    .outlets{
        padding: 10px;
        border-radius: 10px;
    }
    .Content{
        margin-left: -5%;
    }
    .locationBtn{
        margin-left: -6%;
        color: white;
        font-weight: 500;
        border-radius: 3px;
        background-color: blue;
        padding:10px 20px 10px 20px;
        
    }
    .textfield1 select{
        border: 1px solid black;
    }
    .orderInput input{
        width: 50%;
        margin-top: 2%;
        height: 35px;
        border: 1px solid gray;
        border-radius: 5px;
        color:gray
        
    
    }
    .orderInput input::placeholder{
        color:gray;
    }
    .orderInput select::placeholder{
        color:gray;
    }
    .orderInput textarea::placeholder{
        color:gray;
    }
    .orderInput textarea{
        width: 50%;
        margin-top: 2%;
        height: 75px;
        border: 1px solid gray;
        border-radius: 5px;
        resize: none;
        
    
    }
    .orderInput select{
        width: 50%;
        margin-top: 2%;
        height: 35px;
        border: 1px solid gray;
        border-radius: 5px;
        resize: none;
        color: gray;
        
    
    }
    .orderInput input:focus{
        outline: none;
        border: 1px solid rgb(5, 129, 174);
    }
    
    .orderInput textarea:focus{
        outline: none;
        border: 1px solid rgb(5, 129, 174);
    }
    .orderInput select:focus{
        outline: none;
        border: 1px solid rgb(5, 129, 174);
    }
    .neworderTitle{
        color: rgb(63, 63, 63);
        margin-top: 2%;
        font-weight: 500;
    }
    
    .submitBtn{
        padding: 3px 8px 3px 8px;
        margin-top: 2%;
        color: white;
        border-radius: 3px;
        transition-duration: 0.4s;
        background-color: rgb(84, 174, 197);
    }
    .submitBtn:hover{
        background-color: rgb(4, 81, 101);
    
    }
    .rights{
        font-size: 12px;
        margin-top: 3%;
        color: gray
    }
    .rights a{
        color: blue;
    }
}