.HeaderBlock{
    margin:1%;
    text-align: center;
}
.HeaderText{
    color: #1B31A8;
    font-size: 20px;
    opacity: 0.9;
}
span{
    font-weight: bolder;
    color: #1B31A8;
    opacity: 1;
}