/* This is the css files for all the buttons that might be used -: */
.BlueButton{
    background-color: #1B31A8;
    width: 175%;
    height: 6vh;
    border-radius: 85px;
    text-align: center;
    margin-top: 0%;
    margin-left: 40%;
 }
 h3{
     color: white;
 }
 