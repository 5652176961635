@media screen and (min-device-width:768px){
    .topbarWrapper{
        display: flex;
        justify-content: space-between;
        background-color: white;
        height: 60px;
        box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
        -webkit-box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.75);
    }
    .loggedIn{
        font-size: 13px;
        margin-left: 8%;
        margin-top: 3%;
    }

    .topbarLeft{
        width: 200px;
        display: flex;
        align-items: center;
        margin-left: 3%;
       
        
        
    }
    .topbarRight{
        width: 300px;
        display: flex;
        align-items: center;
    }
    .logo{
        height: 80px;
        width: 170px;
        margin-top: -4%;
        object-fit: contain;
        margin-left: 3%;
    }
    .notification{
        margin-left: 6%;
        display: flex;
        align-items: center;
    }
    .notificationNumber{
        position: absolute;
        margin-left: 0.7%;
        color: white;
        padding: 2px;
        border-radius: 49%;
        margin-top: -0.1%;
        font-size: 11px;
        background-color: red;
        
        
    }
    .adminTitle{
        margin-left: 10%;
    }
    .accountImg{
        width: 15%;
        margin-left: 5%;
    }
    .mobileMenu{
        display: none;
    }

}

@media screen and (max-width:468px){
    .topbarWrapper{
        display: flex;
        justify-content: space-between;
        background-color: white;
        height: 60px;
        box-shadow: -1px 4px 7px -1px rgba(0,0,0,0.75);
        -webkit-box-shadow: -1px 4px 7px -1px rgba(0,0,0,0.75);
        -moz-box-shadow: -1px 4px 7px -1px rgba(0,0,0,0.75);
    }
    .loggedIn{
        font-size: 13px;
        margin-left: 8%;
        margin-top: 3%;
    }
    .topbarLeft{
        width: 200px;
        display: flex;
        align-items: center;
        margin-left: 3%;
       
        
        
    }
    .topbarRight{
        width: 200px;
        display: flex;
        align-items: center;
    }
    .logo{
        height: 80px;
        width: 120px;
        margin-top: -4%;
        object-fit: contain;
        margin-left: 3%;
    }
    .notification{
        display: flex;
        align-items: center;
    }
    .notificationNumber{
        position: absolute;
        margin-left: 0.7%;
        color: white;
        padding: 2px;
        border-radius: 49%;
        margin-top: -0.1%;
        font-size: 11px;
        background-color: red;
        
        
    }
    .adminTitle{
        margin-left: 10%;
    }
    .accountImg{
        width: 15%;
        margin-left: 5%;
    }
    .dots{
        display: none;
    }


}