.MapBox{
    height: 50vh;
    width: 70%;
    margin: 20% 20% 0 15%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
input::placeholder{
   
}
.Content{
    margin: 15% 0 0 11%;
}