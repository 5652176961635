.TextFieldBox{
    display: flex;
    margin-top: -10%;

}
.TextField1{
    width: 100%;
   
    
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    
}
.TextField2{
    position: relative;
    width: 100%;
    
    padding: 10px;
    margin-left: 5%;
    border-radius: 10px;
    text-align: center;
}
.TextField1::placeholder{
  
    text-align: center;
}
.TextField2::placeholder{

    text-align: center;
}
.icon{
    position: relative;
}
label{
   
    opacity: 0.5;
}
.DestinationLabel{
    margin-top: 10%;
    opacity: 0.5;
}
